import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'Keep your technology relevant',
    paragraph: ' Tech moves fast, peak performace is lost faster. Meridity can help you make it relevant again.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>              
            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/update.svg')}
                      alt="Features tile icon 01"
                      width={50}
                      height={50} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Website Design
                    </h4>
                  <p className="m-0 text-sm">
                    Get help building a website that effectively sends your message.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/programming.svg')}
                      alt="Features tile icon 02"
                      width={50}
                      height={50} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Website Maintenance
                    </h4>
                  <p className="m-0 text-sm">
                    Keep your site running at peak performance.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={require('./../../assets/images/speedometer.svg')}
                      alt="Features tile icon 03"
                      width={50}
                      height={50} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Website Updates
                    </h4>
                  <p className="m-0 text-sm">
                    Keep your website looking fresh so your message is never outdated.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image-alt mb-16">
                    <Image
                      src={require('./../../assets/images/hard-drive.svg')}
                      alt="Features tile icon 04"
                      width={50}
                      height={50} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    <a href='https://www.salvagedata.com/request-help?partnerid=1064151'>Data Recovery ⧉</a>
                  </h4>
                  <p className="m-0 text-sm">
                    Drives go bad and files go missing. Let Meridity help you get them back. 
                  </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image-alt mb-16">
                    <Image
                      src={require('./../../assets/images/data.svg')}
                      alt="Features tile icon 05"
                      width={50}
                      height={50} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Hardware Installation, Maintenance, Repair
                    </h4>
                  <p className="m-0 text-sm">
                    New installs, consistent check-ups, and upgrades keep technology running smoothly.
                    </p>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image-alt mb-16">
                    <Image
                      src={require('./../../assets/images/warning.svg')}
                      alt="Features tile icon 06"
                      width={50}
                      height={50} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-8">
                    Wire Installation
                    </h4>
                  <p className="m-0 text-sm">
                    Run some new Ethernet, Coax, or any other low voltage cable.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;